import React, { useState } from 'react';
import 'ldrs/ring'

import "./Contact.css";

import InfosContact from './InfosContact';

import iconPhone from '../../../assets/icon/phone.png';
import iconEmail from '../../../assets/icon/email.png';
import iconMarker from '../../../assets/icon/marker.png';
import iconSettings from '../../../assets/icon/settings.png';

import iconCalendly from '../../../assets/icon/calendly-logo.png';

const Contact = () => {

    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [buttonText, setButtonText] = useState("Envoyer le message");
    const [isVisible, setIsVisible] = useState(false);

    const handleNomChange = (event) => {
        setNom(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsVisible(true);

        const formData = {
          mail: email,
          name: nom,
          message: message
        };
        try {
          setButtonText('');
          const response = await fetch('https://www.serveur-mail.maissadev.com/serveur_mail.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
          });
      
          const responseData = await response.json();
          if (response.ok) {
            console.log(responseData.message);
            setTimeout(() => {
                setIsVisible(false);
                setNom('');
                setEmail('');
                setMessage('');
                setButtonText("Envoyé !");
            }, 3000);
          } else {
            console.error(responseData.message);
            setTimeout(() => {
                setIsVisible(false);
                setNom('');
                setEmail('');
                setMessage('');
                setButtonText("Erreur !");
            }, 3000);
          }

        } catch (error) {

          console.error('Erreur lors de l\'envoi de la requête :', error);
          setTimeout(() => {
            setIsVisible(false);
            setNom('');
            setEmail('');
            setMessage('');
            setButtonText("Erreur !");
        }, 3000);
        } finally {
          
        setTimeout(() => {
          setButtonText("Envoyer le message");
        }, 5000);
        }
      };

    return (
        <div className='contact-container'>


            <h2 className='contact-title'>Contact</h2>
            <div className='contact-all-container'>
            <div className='contact-form-container'>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <input
                                type="text"
                                value={nom}
                                onChange={handleNomChange}
                                placeholder="Nom"
                                required
                                className='contact-input'
                            />
                        </label>

                        <label>
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="E-mail"
                                required
                                className='contact-input'
                            />
                        </label>

                        <label>
                            <textarea
                                value={message}
                                onChange={handleMessageChange}
                                placeholder="Message"
                                required
                                className='contact-textarea'
                            />
                        </label>
                        <button type="submit" className='contact-button'>
            {buttonText}

<div className='spinner'>
{isVisible ? (
  <div className="visibleClass">
<l-leapfrog
  size="30"
  speed="2.5" 
  color="#FEFBFE"
></l-leapfrog>
</div>) : null}
</div>
          </button>
                    </form>
                </div>

                <div className='contact-left-container'>
                    <div className='contact-infos-container'>
                        <div>
                            <InfosContact
                                icon={iconPhone}
                                title="Téléphone"
                                subtitle="+33 (0) 7 45 31 65 41"
                            />
                        </div>
                        <div>
                            <InfosContact
                                icon={iconEmail}
                                title="E-mail"
                                subtitle="contact@maissadev.com"
                            />
                        </div>
                        {/* <div>
                            <InfosContact
                                icon={iconMarker}
                                title="Adresse"
                                subtitle="15 rue de belfort, 92110, FR"
                            />
                        </div> */}
                        <div>
                            <InfosContact
                                icon={iconSettings}
                                title="S.I.R.E.N"
                                subtitle="979 912 029"
                            />
                        </div>
                    </div>
                    {/* <div className='contact-calendly'>  */}
                        <a className='contact-calendly-container' href="https://calendly.com/maissadev/30min" target="_blank">
                            <div className='contact-calendly-content'>
                                <img src={iconCalendly} alt="Icon" className='calendly-icon-style'/> 
                                <p>Rendez-vous Calendly</p>
                            </div>
                        </a>
                    {/* </div> */}
                    {/* <div className='contact-reseaux-container'> LEs reésaux</div> */}
                    {/* <a className='contact-calendly-container margin-top' href="https://calendly.com/maissadev/30min" target="_blank">
                            <div className='contact-calendly-content'>
                                <img src={iconCalendly} alt="Icon" className='calendly-icon-style'/> 
                                <p>Rendez-vous Calendly</p>
                            </div>
                        </a> */}
                </div>
               
            </div>
        </div>
    );
};

export default Contact;
