import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './composants/Home/navbar/NavBar';
import Home from './composants/Home/home/Home';
import Footer from './composants/Home/footer/Footer';
import Projets from './composants/Projets/Projets';
import RealisationFocus from './composants/RealisationsFocus/RealisationFocus';
import AvisAll from './composants/Avis/AvisAll';

import MentionsLegales from './composants/PagesLegales/MentionsLegales';
import PolitiqueConfidentialite from './composants/PagesLegales/PolitiqueConfidentialite';
import CGV from './composants/PagesLegales/CGV';

import Error from './composants/Erreur/Error';
import ScrollToTop from './composants/ScrollToTop';



function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projets" element={<Projets />} />
          <Route path="/projets/:id" element={<RealisationFocus />} />
          <Route path="/mentions-legales" element={<MentionsLegales/>} />
          <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite/>} />
          <Route path="/conditions-generales-ventes" element={<CGV/>} />
          <Route path="/avis" element={<AvisAll/>} />

          <Route path="*" element={<Error />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
