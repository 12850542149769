import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./RealisationFocus.css";
import starIcon from '../../assets/icon/star.png';
import data from '../../assets/data.json'
import Avis from '../Avis/Avis'; // Import du composant Avis

// Import des images
import md1 from '../../assets/projets/md1.png'
import ave1 from '../../assets/projets/ave1.png';
import ave2 from '../../assets/projets/ave2.png';
import ave3 from '../../assets/projets/ave3.png';
import ga1 from '../../assets/projets/ga1.png';
import ga2 from '../../assets/projets/ga2.png';
import ga3 from '../../assets/projets/ga3.png';
import fsa1 from '../../assets/projets/fsa1.png';
import fsa2 from '../../assets/projets/fsa2.png';
import fsa3 from '../../assets/projets/fsa3.png';
import fsa4 from '../../assets/projets/fsa4.png';
import rc1 from '../../assets/projets/rc1.png';
import rc2 from '../../assets/projets/rc2.png';
import rc3 from '../../assets/projets/rc3.png';
import rc4 from '../../assets/projets/rc4.png';
import sp1 from '../../assets/projets/sp1.png';
import sp2 from '../../assets/projets/sp2.png';
import sp3 from '../../assets/projets/sp3.png';
import sp4 from '../../assets/projets/sp4.png';
import yt1 from '../../assets/projets/yt1.png';
import pa1 from '../../assets/projets/pa1.png';
import lm1 from '../../assets/projets/lm1.png';
import lm2 from '../../assets/projets/lm2.png';
import lm3 from '../../assets/projets/lm3.png';
import lm4 from '../../assets/projets/lm4.png';
import ft1 from '../../assets/projets/ft1.png';
import ft2 from '../../assets/projets/ft2.png';
import ft3 from '../../assets/projets/ft3.png';
import ft4 from '../../assets/projets/ft4.png';
import lad1 from '../../assets/projets/lad1.png';
import lad2 from '../../assets/projets/lad2.png';
import lad3 from '../../assets/projets/lad3.png';
import lad4 from '../../assets/projets/lad4.png';
import rd1 from '../../assets/projets/rd1.png';
import rd2 from '../../assets/projets/rd2.png';
import rd3 from '../../assets/projets/rd3.png';
import rd4 from '../../assets/projets/rd4.png';

const RealisationFocus = () => {
    const stars = 5;
    const { id } = useParams();
    const [projet, setProjet] = useState(null);

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);

    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
      setSelectedImage(image);
      setShowImageModal(true);
      document.body.classList.add('no-scroll'); // Ajoute une classe au corps pour désactiver le défilement
  };

  const handleCloseModal = () => {
      setShowImageModal(false);
      setSelectedImage(null);
      document.body.classList.remove('no-scroll'); // Supprime la classe pour réactiver le défilement
  };

    const data_images = [
        {
          "id": 1,
          "image_1": md1,
          "image_2": null,
          "image_3": null,
          "image_4": null
        },
        {
          "id": 2,
          "image_1": ave1,
          "image_2": ave2,
          "image_3": ave3,
          "image_4": null
        },
        {
          "id": 3,
          "image_1": ga1,
          "image_2": ga2,
          "image_3": ga3,
          "image_4": null
        },
        {
          "id": 4,
          "image_1": fsa1,
          "image_2": fsa2,
          "image_3": fsa3,
          "image_4": fsa4
        },
        {
          "id": 5,
          "image_1": rc1,
          "image_2": rc2,
          "image_3": rc3,
          "image_4": rc4
        },
        {
          "id": 6,
          "image_1": sp1,
          "image_2": sp2,
          "image_3": sp3,
          "image_4": sp4
        },
        {
          "id": 7,
          "image_1": yt1,
          "image_2": null,
          "image_3": null,
          "image_4": null
        },
        {
          "id": 8,
          "image_1": pa1,
          "image_2": null,
          "image_3": null,
          "image_4": null
        },
        {
          "id": 9,
          "image_1": lm1,
          "image_2": lm2,
          "image_3": lm3,
          "image_4": lm4
        },
        {
          "id": 10,
          "image_1": ft1,
          "image_2": ft2,
          "image_3": ft3,
          "image_4": ft4
        },
        {
          "id": 11,
          "image_1": lad1,
          "image_2": lad2,
          "image_3": lad3,
          "image_4": lad4
        },
        {
          "id": 12,
          "image_1": rd1,
          "image_2": rd2,
          "image_3": rd3,
          "image_4": rd4
        }
    ];

    useEffect(() => {
        console.log(data)
        const projet = data.find(item => item.id.toString() === id);
        setProjet(projet);
        console.log(projet)

        if (projet) {
            const images = data_images.find(item => item.id === projet.id);
            if (images) {
                setImage1(images.image_1);
                setImage2(images.image_2);
                setImage3(images.image_3);
                setImage4(images.image_4);
            }
        }

    }, [id]);

    if (!projet) {
        return <div>Chargement...</div>;
    }

    const starComponents = [];
    for (let i = 0; i < stars; i++) {
        starComponents.push(<img key={i} src={starIcon} alt="Icon" className='avis-home-icon-style' />);
    }

    // const outilsDivs = projet.outils.split(',').map((outil, index) => (
    //     <div key={index} className="realisation-focus-outils">{outil.trim()}</div>
    // ));

    const linkClass = projet.etat === 1 ? 'realisation-focus-link green' : 'realisation-focus-link grey';
    const linkHref = projet.etat === 1 ? projet.adresse_site : '#';

    const estDesactive = projet.etat === 0;

    return (
        <>
<div className='realisation-focus-container'>
            <div>
                <h2 className='realisation-focus-title'>{projet.nom_site}</h2>
            </div>

            <div className='realisation-focus-infos-projet'>
                <div>
                {image1 && <img src={image1} alt="Image 1" className="realisation-projets-image" onClick={() => handleImageClick(image1)} />}
                </div>
                <div className='realisation-focus-all-infos-projet'>
                    {/* <div className='realisation-focus-outils-container'>{outilsDivs} </div> */}
                    <div className='realisation-focus-desc'>{projet.description}</div>
                    <a href={linkHref} target="_blank" className={linkClass}> Lien vers le site</a>

                </div>
            </div>

            <div className='realisation-focus-mini-images-container'>
                {image2 && <img src={image2} alt="Image 2" className="realisation-focus-mini-images" onClick={() => handleImageClick(image2)} />}
                {image3 && <img src={image3} alt="Image 3" className="realisation-focus-mini-images"  onClick={() => handleImageClick(image3)} />}
                {image4 && <img src={image4} alt="Image 4" className="realisation-focus-mini-images" onClick={() => handleImageClick(image4)} />}
            </div>

            

        </div>
        {projet.avis && (
            <Avis projet={projet} image={image1} /> )}

            {showImageModal && (
                <div className="image-modal-container" onClick={handleCloseModal}>
                     <img src={selectedImage} className="image-modal"/>
                    {/* <div className="image-modal-content">
                       
                    </div> */}
                </div>
            )}
            </>
    );
};

export default RealisationFocus;
