import React from 'react';
import "./Home.css";

import Banner from '../banner/Banner';
import Services from '../services/Services';
import Competences from '../competences/Competences';
import Ebook from '../ebook/Ebook';
import Realisations from '../realisations/Realisations';
import AvisHome from '../avis/AvisHome';
import Contact from '../contact/Contact';
import Recontact from '../recontact/Recontact';

const Home = () => {
    return (
        <>
        {/* <Banner /> */}
            {/* <div id="services"><Services /></div>
            <div id="competences"><Competences /></div> */}
            <div id="ebook"><Ebook /></div>
            <div id="realisations"><Realisations /></div>
            <div id="avis"><AvisHome /></div>
            <div id="contact"><Contact /></div>
            <Recontact />
        </>
    );
};

export default Home;