import React from 'react';
import './PagesLegales.css'

const MentionsLegales = () => {
  return (
    <div className="pages-legales-container">
      <h2 className='pages-legales-title'>Mentions Légales</h2>

      <div className="pages-legales-content">
        <h4>Informations légales</h4>
        <p>
          Le site web Maissa Dev est édité par Maïssa Mezhoud.
        </p>

        <h4>Contact</h4>
        <p>
          Vous pouvez nous contacter par e-mail à l'adresse contact@maissadev.com.
        </p>

        <h4>Hébergement</h4>
        <p>
          Ce site est hébergé par Always Data, dont le siège social est situé à 91 rue du Faubourg Saint Honoré 75008 Paris.
        </p>

        <h4>Propriété intellectuelle</h4>
        <p>
          Tous les éléments présents sur ce site (textes, images, logos, etc.) sont la propriété
          exclusive de Maissa Dev et sont protégés par les lois relatives à la
          propriété intellectuelle. Toute reproduction, distribution ou utilisation sans autorisation
          préalable est strictement interdite.
        </p>

        <h4>Données personnelles</h4>
        <p>
          Les informations collectées à partir de ce site sont utilisées uniquement à des fins de
          traitement de vos demandes. Conformément à la loi RGPD (Règlement Général sur la Protection des Données),
          vous disposez d'un droit d'accès, de rectification et de suppression de vos données.
        </p>

        <h4>Cookies</h4>
        <p>
          Ce site utilise des cookies pour améliorer l'expérience de navigation des utilisateurs.
          Vous pouvez configurer votre navigateur pour refuser les cookies.
          <br/> <br/> Les cookies sont de petits fichiers texte stockés sur votre ordinateur par votre navigateur web pour collecter des informations standard de journal Internet et des informations sur le comportement des visiteurs. Ces informations sont utilisées pour suivre et surveiller l'utilisation du site web, afin de nous permettre d'améliorer son contenu et son fonctionnement.
          <br/> <br/> Les cookies utilisés sur ce site web sont conservés pendant une durée strictement nécessaire pour atteindre leur finalité. La durée de conservation peut varier en fonction du type de cookie, mais ils ne sont pas conservés plus longtemps que nécessaire.
          Les utilisateurs ont le droit de retirer leur consentement à l'utilisation des cookies à tout moment. Vous pouvez gérer vos préférences de cookies en ajustant les paramètres de votre navigateur pour refuser les cookies ou être averti lorsque des cookies sont envoyés. Veuillez noter que le retrait du consentement à l'utilisation des cookies peut affecter certaines fonctionnalités du site web.
        </p>

        <h4>Liens externes</h4>
        <p>
          Ce site peut contenir des liens vers des sites externes. Nous ne pouvons être tenus
          responsables du contenu ou des pratiques de ces sites.
        </p>

        <h4>Responsabilité</h4>
        <p>
          Nous nous efforçons de maintenir les informations de ce site à jour et correctes.
          Cependant, nous ne pouvons garantir leur exactitude et déclinons toute responsabilité
          en cas d'erreur ou d'omission.
        </p>
      </div>
    </div>
  );
};

export default MentionsLegales;
