import React from 'react';
import "./Footer.css";
import { Link } from 'react-router-dom';

import iconPhone from '../../../assets/icon/phone.png';
import iconEmail from '../../../assets/icon/email.png';
import iconMarker from '../../../assets/icon/marker.png';

const Footer = () => {
    return (
        <footer >
            <div className="footer-columns">

           
            <div className="footer-column">
                <h3> Pages légales </h3>
                <ul>
                <li><Link to="/mentions-legales">Mentions Légales</Link></li>
        <li><Link to="/politique-confidentialite">Politique de confidentialité</Link></li>
        <li><Link to="/conditions-generales-ventes">Conditions Generales de Ventes (CGV)</Link></li>
                </ul>
            </div>
            {/* <div className="footer-column">
                <h3>Services</h3>
                <ul>
                    <li><a href="#">Création de site web</a></li>
                    <li><a href="#">Refonte de site web</a></li>
                    <li><a href="#">Maintenance</a></li>
                    <li><a href="#">Développement spécifiques</a></li>
                </ul>
            </div>
            <div className="footer-column">
                <h3>À propos</h3>
                <ul>
                    <li><a href="#">Compétences</a></li>
                    <li><a href="#">Réalisations</a></li>
                    <li><a href="#">Avis</a></li>
                </ul>
            </div> */}
            <div className="footer-column">
                <h3>Informations</h3>
                <ul>
                    <li className='infos-footer-column'><img src={iconPhone} alt="Icon" className='footer-icon-style'/> +33 (0) 7 45 31 65 41</li>
                    <li className='infos-footer-column'><img src={iconEmail} alt="Icon" className='footer-icon-style'/> contact@maissadev.com</li>
                    {/* <li className='infos-footer-column'><img src={iconMarker} alt="Icon" className='footer-icon-style'/> 15 rue de belfort, 92110, Clichy</li> */}
                </ul>
            </div>
            </div>

            <div className='designed-by-footer'>

            Copyright 2024 © Designed & Developed by <a href="https://www.maissadev.com" target="_blank">Maissa Dev</a>

            </div>
        </footer>
    );
};

export default Footer;
