import React, { useState, useEffect } from 'react';
import "./Projets.css";
import { Link } from 'react-router-dom';
import data from '../../assets/data.json'
const img1 = '../../assets/projets/ave1.png'

const Projets = () => {
    const [projets, setProjets] = useState([]);
    const [images, setImages] = useState({});

    // useEffect(() => {
    //     const fetchProjets = async () => {
    //         try {
    //             const response = await fetch('http://api-maissadev.xita4709.odns.fr/projets');
    //             if (!response.ok) {
    //                 throw new Error('Erreur lors de la récupération des projets');
    //             }
    //             const data = await response.json();
    //             setProjets(data);

    //             const loadedImages = {};
    //             for (const projet of data) {
    //                 const imageData = projet.image_1; // Charger uniquement image_1
    //                 if (imageData) {
    //                     const arrayBuffer = Uint8Array.from(imageData.data).buffer;
    //                     const imageUrl = URL.createObjectURL(new Blob([arrayBuffer]));
    //                     loadedImages[projet.id] = imageUrl;
    //                 }
    //             }
    //             setImages(loadedImages);
    //         } catch (error) {
    //             console.error('Erreur:', error);
    //         }
    //     };
    //     fetchProjets();
    // }, []);
    useEffect(() => {
        try {
            const sortedProjects = [...data].sort((a, b) => {
                // Si l'état est différent, les projets avec un état de 0 vont à la fin
                if (a.etat !== b.etat) {
                    return b.etat - a.etat;
                }
                // Trier par ID décroissant
                return b.id - a.id;
            });
            setProjets(sortedProjects);
        } catch (error) {
            console.error('Erreur:', error);
        }
    }, []);

    return (
        <div className='projects-container'>
            <h1 className='projects-title'> Mes réalisations </h1>
            <div className='all-projects-container'>
                {projets.map(projet => (
                    
                    <Link to={`/projets/${projet.id}`} className="project-link" key={projet.id}>
                        <div className="project-item">
                                <img
                                    src={projet.image_1}
                                    alt={`${projet.nom_site}`}
                                    className="project-item-image"
                                />
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Projets;
