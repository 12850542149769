import React, { useState } from 'react';
import "./NavBar.css";

import { Link } from 'react-router-dom';

// import InfosContact from './InfosContact';

import icon from '../../../assets/icon/phone.png';
import arrowIcon from '../../../assets/icon/arrow-right.png';

const NavBar = () => {

    return (
        <nav class="navbar">
             <Link to="/" className='navbar-logo'>Maissa Dev</Link>
            {/* <ul class="navbar-menu">
                <li><Link to="/">Accueil</Link></li>
                <li><Link to="#services">Services</Link></li>
                <li><Link to="#realisations">Réalisations</Link></li>
                <li><Link to="#avis">Avis</Link></li>
                <li><Link to="#contact">Contact</Link></li>
            </ul> */}
        </nav>
    );
};

export default NavBar;
