import React, { useState } from 'react';
import "./Ebook.css";


// import InfosContact from './InfosContact';

import ebook1 from '../../../assets/img/ebook2.png';
import ebook2 from '../../../assets/img/ebook1.png';


const Ebook = () => {

  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState("Recevoir l'e-book");
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVisible(true);
    setIsSubmitting(true);
    const formData = {
      mail: email
    };
    try {
      setButtonText('');
      const response = await fetch('https://www.serveur-mail.maissadev.com/serveur_book.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();
      console.log(responseData.message);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la requête :', error);
      setTimeout(() => {
        setIsVisible(false);
        setEmail('');
        setButtonText("Erreur !");
      }, 3000);
    } finally {
      setTimeout(() => {
        setIsVisible(false);
        setEmail('');
        setButtonText("Envoyé !");
      }, 3000);
      setTimeout(() => {
        setIsSubmitting(false);
        setButtonText("Recevoir l'e-book");
      }, 5000);
    }
  };

  return (
    <div className='ebook-container'>

      <h2 className='ebook-title'>Découvrez mon <span className='ebook-title-wrap'>e-book</span> sur les tendances web 2024</h2>

      <div className='ebook-subcontainer'>


        <img src={ebook2} className='cover-book-1' />

        <div className='ebook-center'>



          <p className='ebook-resume'>
            Les designs tendances, les pratiques pour augmenter votre taux de conversion, les erreurs à éviter ...
          </p>

          <form onSubmit={handleSubmit}>
            <div className='ebook-form-container'>
              <label>
                <input
                  type="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="E-mail"
                  required
                  className='ebook-input'
                />
              </label>
              <button type="submit" className={isSubmitting ? 'ebook-button submitting' : 'ebook-button'} disabled={isSubmitting} >  {buttonText}

                <div className='spinner'>
                  {isVisible ? (
                    <div className="visibleClass">
                      <l-leapfrog
                        size="30"
                        speed="2.5"
                        color="#FEFBFE"
                      ></l-leapfrog>
                    </div>) : null}
                </div></button>
            </div>
          </form>
        </div>
        <img src={ebook1} className='cover-book-2' />

      </div>
    </div>
  );
};

export default Ebook;
