import React from 'react';
import "./InfosContact.css";

const InfosContact = ({ icon, title, subtitle }) => {
    return (
        <div className="infosContact-container">
            {/* Première ligne */}
            <div className="infosContact-row">
                <div className="infosContact-icon"> {/* Icône */}
                    {/* Utilisation de l'icône reçue en tant que prop */}
                    <img src={icon} alt="Icon" className='infosContact-icon-style'/>
                </div>
                <div className="infosContact-content"> {/* Contenu */}
                    {/* Utilisation du titre reçu en tant que prop */}
                    <p className="infosContact-title">{title}</p> {/* Titre */}
                </div>
            </div>

            {/* Deuxième ligne */}
            <div className="infosContact-row">
                <div className="infosContact-content"> {/* Contenu */}
                    {/* Utilisation du sous-titre reçu en tant que prop */}
                    <h3 className="infosContact-subtitle">{subtitle}</h3> {/* Sous-titre */}
                </div>
            </div>
        </div>
    );
};

export default InfosContact;
